import { HttpClient } from "./HttpClient";
import { AxiosResponse } from "axios";
import { ISubscription } from "@/models/Subscription";

export class CompanyService {
  static GetSubscriptionDetails(): Promise<AxiosResponse> {
    return HttpClient.get("/company/subscription-details");
  }

  static BuySubscription(subscription: ISubscription): Promise<AxiosResponse> {
    return HttpClient.post("/company/buy-subscription", subscription);
  }
}
