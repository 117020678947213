
import { Vue, Component } from "vue-property-decorator";
import { ISubscriptionDetails } from "@/models/SubscriptionDetails";
import Subscription from "@/components/Subscription.vue";
import { GetLocale } from "@/utility/LanguageHelper";
import { ISubscription } from "@/models/Subscription";
import { CompanyService } from "@/api/CompanyServiceApi";
import user from "@/store/modules/UserModule";

@Component({
  components: {
    Subscription
  }
})
export default class DashboardSubscription extends Vue {
  subscription: ISubscription = {
    invoicingMethodId: 0,
    priceModelId: 0,
    subscribedRegions: [],
    subscribedCategories: [],
    discountCodeId: 0
  };

  created() {
    if (this.subscriptionDetails == null) {
      return;
    }
    const priceModel = this.subscriptionDetails.subscriptionPriceModel;
    const invoicingMethod = this.subscriptionDetails.invoicingMethod;

    this.subscription.priceModelId = priceModel.id;
    this.subscription.invoicingMethodId = invoicingMethod.id;

    this.subscriptionDetails.subscribedRegions.forEach(region => {
      this.subscription.subscribedRegions.push({ id: region.id });
    });

    this.subscriptionDetails.subscribedCategories.forEach(category => {
      this.subscription.subscribedCategories.push({ id: category.id });
    });
  }

  get subscriptionDetails(): ISubscriptionDetails | null {
    return user.subscriptionDetails;
  }

  translateRegion(region: string): string {
    if (GetLocale() == "fi") {
      return this.$t(`REGION.${region}`).toString();
    }
    return region;
  }

  get subscriptionHasExpired(): boolean {
    return this.subscriptionDetails!.expired;
  }

  async buySubscription() {
    this.$swal
      .fire({
        titleText: this.$t("MODAL.SUBSCRIPTION_CONFIRMATION.TITLE").toString(),
        text: this.$t("MODAL.SUBSCRIPTION_CONFIRMATION.SUB_TITLE").toString(),
        confirmButtonText: "Ja",
        icon: "question",
        showCloseButton: true
      })
      .then(async result => {
        if (result.isConfirmed) {
          await CompanyService.BuySubscription(this.subscription)
            .then(() => {
              this.$router.push("/dashboard/?subscriptionresult=SUCCESS");
              this.$router.go(0);
            })
            .catch(() => {
              this.$swal.fire({
                titleText: this.$t(
                  "MODAL.SUBSCRIPTION_CONFIRMATION.TITLE"
                ).toString(),
                text: this.$t(
                  "VALIDATION_ERROR.INTERNAL_SERVER_ERROR"
                ).toString(),
                confirmButtonText: "Ok",
                icon: "error"
              });
            });
        }
      });
  }

  setSubscription(subscription: ISubscription) {
    this.subscription = subscription;
  }
}
